// Add your custom JS here.
var actualyear = new Date().getFullYear();
document.getElementById('actualyear').textContent = actualyear;

// Randomize cards
[...document.querySelectorAll('.randomize')]
	.sort((a, b) => 0.5 - Math.random())
	.forEach((elem, index) => {
		let parentElement = elem.parentNode;
		parentElement.insertBefore(elem, parentElement.children[index]);
	});

// Datapage bottom nav fade
// Declare all variables at the top of the code block
var scrollOffset = 200;
var datapageMenuBottom = document.getElementById('datapage-menu-bottom');

// Check if the element exists before adding the event listener
if (datapageMenuBottom) {
	// Add event listener to window object
	window.addEventListener('scroll', function () {
		var scrollPos = window.scrollY || window.pageYOffset;
		if (scrollPos > scrollOffset) {
			datapageMenuBottom.classList.add('visible');
		} else {
			datapageMenuBottom.classList.remove('visible');
		}
	});
}

// UI-UX elemek
//Collapse - gomb toggle

document.addEventListener('DOMContentLoaded', function () {
	// Először megkeressük a gombot a class alapján
	const buttons = document.getElementsByClassName('collapse-toggle-btn');

	// Iterálunk az összes találaton
	Array.from(buttons).forEach(function (button) {
		// Hozzáadjuk a kattintás eseménykezelőt
		button.addEventListener('click', function () {
			// Megkeressük a gomb aktuális szövegét
			const currentText = button.textContent.trim();

			// Ellenőrizzük, hogy a szöveg tartalmazza-e az "elrejtés" szót
			const isTextHidden = currentText.includes('Elrejtés...');

			// A szöveg elrejtéséhez vagy megjelenítéséhez módosítjuk a gomb szövegét
			button.textContent = isTextHidden ? 'Továbbiak megjelenítése...' : 'Elrejtés...';
		});
	});
});

//Reload embed
window.reloadEmbed = function () {
	var divs = document.getElementsByClassName('embed-reload');
	for (var i = 0; i < divs.length; i++) {
		var div = divs[i];
		div.innerHTML = div.innerHTML;
	}
};

// Type writer stylee yo
window.onload = function () {
	var element = document.getElementById('typewriter');
	if (element) {
		var text = element.innerText;
		element.innerText = '';
		typewriter(element, text, 50);
	}
};

function typewriter(element, text, speed) {
	var i = 0;
	var lastTime = 0;

	function type(now) {
		if (!lastTime) {
			lastTime = now;
		}
		var elapsedTime = now - lastTime;
		if (elapsedTime > speed && i < text.length) {
			element.innerHTML += text.charAt(i);
			i++;
			lastTime = now;
		}
		if (i < text.length) {
			requestAnimationFrame(type);
		}
	}
	requestAnimationFrame(type);
}

// Method to change an element's parent
function setParent(el, newParent) {
	if (el && newParent) {
		newParent.appendChild(el);
	}
}

function handleScreenSizeChange() {
	var screenWidth = window.innerWidth;

	var cardMappings = [
		{
			className: 'lifemeaning-card',
			parentClass: 'lifemeaning-cont',
			smallParentClass: 'lifemeaning-sm',
		},
		{
			className: 'aiandquote-card',
			parentClass: 'aiandquote-cont',
			smallParentClass: 'aiandquote-sm',
		},
		// További elemeket itt adhatsz hozzá a megfelelő osztályokkal
	];

	var elements = cardMappings.map(function (mapping) {
		return {
			card: document.querySelector('.' + mapping.className),
			parent: document.querySelector('.' + mapping.parentClass),
			smallParent: document.querySelector('.' + mapping.smallParentClass),
		};
	});

	elements.forEach(function (element) {
		if (screenWidth < 991) {
			setParent(element.card, element.smallParent);
		} else {
			setParent(element.card, element.parent);
		}
	});
}

window.addEventListener('DOMContentLoaded', handleScreenSizeChange);
window.addEventListener('resize', handleScreenSizeChange);

/* ptk alerct close */

// Ellenőrzi, hogy a cookie beállítása előtt hány nap telt el az utolsó bezárás óta
function daysSinceLastClose() {
	var lastClose = localStorage.getItem('lastAlertClose');
	if (lastClose) {
		var oneDay = 24 * 60 * 60 * 1000; // 1 nap miliszekundumban
		var lastCloseDate = new Date(lastClose);
		var today = new Date();
		var diffDays = Math.round(Math.abs((today - lastCloseDate) / oneDay));
		return diffDays;
	}
	return Infinity;
}

// Beállítja a cookie-t és a bezárás dátumát
function setAlertClosedCookie() {
	var today = new Date();
	localStorage.setItem('lastAlertClose', today);
}

// Ellenőrzi, hogy meg kell-e jeleníteni az alertet
function shouldShowAlert() {
	var daysSinceClose = daysSinceLastClose();
	return daysSinceClose >= 5;
}

// Ellenőrzi és kezeli az alert megjelenítését vagy elrejtését
function handleAlertDisplay() {
	var alertWindow = document.getElementById('alert-window');
	if (alertWindow) {
		if (shouldShowAlert()) {
			alertWindow.style.display = 'block';
		} else {
			alertWindow.style.display = 'none';
		}
	}
}

// Kezdeti ellenőrzés az oldal betöltésekor
document.addEventListener('DOMContentLoaded', function () {
	handleAlertDisplay();
});

// Az alert bezárása után beállítja a cookie-t és újraellenőrzi a megjelenítést
var closeButton = document.querySelector('.ptk-alert .btn-close');
if (closeButton) {
	closeButton.addEventListener('click', function () {
		setAlertClosedCookie();
		handleAlertDisplay();
	});
}

// Az "Értettem" gombra kattintva beállítja a cookie-t és újraellenőrzi a megjelenítést
var confirmButton = document.getElementById('confirm-button');
if (confirmButton) {
	confirmButton.addEventListener('click', function () {
		setAlertClosedCookie();
		handleAlertDisplay();
	});
}

// Anchor helyett JS scroll - EPIC
// A JavaScript eseménykezelő a .scroll-link osztályú linkekre kattintásra meghívja a scrollToElement() függvényt
var scrollLinks = document.querySelectorAll('.scroll-link');
for (var i = 0; i < scrollLinks.length; i++) {
	scrollLinks[i].addEventListener('click', function (e) {
		e.preventDefault(); // Megakadályozza az alapértelmezett link működést
		var targetId = this.getAttribute('data-target');
		scrollToElement(targetId, -60); // Offset: -60 pixel felfelé
	});
}

// JavaScript függvény a görgetéshez
function scrollToElement(id, offset) {
	var element = document.getElementById(id);
	var elementPosition = element.getBoundingClientRect().top;
	var offsetPosition = elementPosition + window.pageYOffset;
	window.scroll({
		top: offsetPosition + offset,
		behavior: 'smooth',
	});
}

// woocommerce kosár frissítés
jQuery(document).ready(function ($) {
	$(document.body).on('added_to_cart removed_from_cart', function () {
		$.ajax({
			type: 'POST',
			url: wc_add_to_cart_params.ajax_url,
			data: {
				action: 'get_cart_count',
			},
			success: function (response) {
				$('.cart-contents-count').text(response);
			},
		});
	});
});

//Woocommerce elrejti a short descriptionbol ami nem kell
document.addEventListener('DOMContentLoaded', function () {
	if (document.body.classList.contains('single-product')) {
		var hiddenElements = document.querySelectorAll('.loop-hidden');
		hiddenElements.forEach(function (element) {
			element.style.display = 'block';
		});
	}
});

// Carousel Blockquote

function adjustCarouselHeight(carouselId) {
	var carousel = document.getElementById(carouselId.substring(1)); // Optimálisabb elem kiválasztás
	var items = carousel.getElementsByClassName('carousel-item'); // Gyorsabb elem kiválasztás
	var maxHeight = 0;

	Array.from(items).forEach(function (item) {
		var totalHeight = item.offsetHeight;
		if (totalHeight > maxHeight) {
			maxHeight = totalHeight;
		}
	});

	Array.from(items).forEach(function (item) {
		item.style.minHeight = maxHeight + 'px';
	});
}

var carouselId = '#carouselExampleIndicators';
adjustCarouselHeight(carouselId);

window.addEventListener('load', function () {
	adjustCarouselHeight(carouselId);
});

// Debouncing a resize eseményhez
let resizeTimer;
window.addEventListener('resize', function () {
	clearTimeout(resizeTimer);
	resizeTimer = setTimeout(function () {
		adjustCarouselHeight(carouselId);
	}, 250);
});

var carouselElement = document.querySelector(carouselId);
carouselElement.addEventListener('slid.bs.carousel', function () {
	adjustCarouselHeight(carouselId);
});
